.publiko-button-icon {
  color: rgba(224, 39, 41, 0.6);
  font-size: 30px;
  cursor: pointer; }
  .publiko-button-icon:hover {
    color: rgba(224, 39, 41, 0.8); }
  .publiko-button-icon:active {
    color: rgba(224, 39, 41, 0.95); }

.publiko-button-icon-circle {
  cursor: pointer;
  color: rgba(224, 39, 41, 0.95);
  height: 40px !important;
  min-width: 40px !important;
  max-width: 40px;
  padding: 8px;
  border-radius: 20px;
  box-sizing: border-box;
  background: rgba(231, 231, 231, 0.95);
  color: white; }
  .publiko-button-icon-circle:hover {
    background: rgba(224, 39, 41, 0.95);
    color: white; }
  .publiko-button-icon-circle:active {
    background: rgba(224, 39, 41, 0.8); }

.publiko-position-button {
  text-align: center;
  color: rgba(224, 39, 41, 0.95);
  width: 100%;
  height: 40px;
  line-height: 36px;
  border: rgba(224, 39, 41, 0.95) 2px solid;
  border-radius: 20px;
  font-weight: bold;
  box-sizing: border-box;
  cursor: pointer; }
  .publiko-position-button:hover {
    background: rgba(224, 39, 41, 0.8);
    color: white; }
  .publiko-position-button.active {
    background: rgba(224, 39, 41, 0.95);
    color: white; }
    .publiko-position-button.active:hover {
      background: rgba(224, 39, 41, 0.95);
      color: white; }
  .publiko-position-button:active {
    background: rgba(224, 39, 41, 0.95); }

.publiko-category-tags {
  padding: 5px 15px; }
  .publiko-category-tags li {
    font-size: 12px;
    padding: 5px 10px;
    background: rgba(231, 231, 231, 0.95);
    color: rgba(224, 39, 41, 0.95);
    margin: 2px 5px;
    border-radius: 5px; }

.publiko-message-button {
  background: rgba(224, 39, 41, 0.95);
  color: white;
  text-align: center;
  padding: 10px 15px;
  cursor: pointer; }
  .publiko-message-button:hover {
    background: rgba(231, 231, 231, 0.95);
    color: rgba(224, 39, 41, 0.95); }

.publiko-message-card-button {
  display: flex;
  text-align: center;
  align-content: center;
  justify-content: center;
  padding: 0px 15px;
  padding-top: 50px;
  min-height: 300px;
  cursor: pointer; }

.publiko-warn {
  color: rgba(224, 39, 41, 0.95); }

.modal {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: rgba(31, 31, 31, 0.5);
  z-index: 999; }
  .modal .close-cover {
    position: absolute;
    width: 100%;
    height: 100%; }
  @media only screen and (min-width: 40.063em) {
    .modal {
      display: flex;
      justify-content: center;
      align-items: center; } }
  .modal section {
    position: absolute;
    bottom: 0px;
    width: 100%;
    background: white;
    min-height: 50px;
    height: auto; }
    .modal section > .svg-icon {
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: pointer;
      font-size: 30px;
      font-style: normal;
      color: rgba(224, 39, 41, 0.95); }
    @media only screen and (min-width: 40.063em) {
      .modal section {
        display: flex;
        position: relative;
        width: 480px;
        max-height: 800px;
        overflow: display; } }
  .modal-title {
    color: rgba(31, 31, 31, 0.95);
    font-size: 18px;
    font-weight: bold;
    margin: 10px; }
  .modal-subtitle {
    color: rgba(122, 122, 122, 0.95);
    font-size: 14px;
    font-weight: bold;
    margin: 15px; }
  .modal-warning {
    color: rgba(224, 39, 41, 0.95);
    font-weight: bold;
    font-size: 14px;
    margin: 15px; }
