#app {
  padding-bottom: 20px;
  background: rgba(231, 231, 231, 0.95); }
  #app > footer {
    text-align: center;
    margin: 20px 0px; }

#p-counter {
  position: fixed;
  top: -2px;
  right: 10px;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  border: rgba(224, 39, 41, 0.95) 2px solid;
  background: rgba(255, 255, 255, 0.8);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 30px;
  color: rgba(224, 39, 41, 0.95); }

#webpush-request-enable-bar {
  position: fixed;
  bottom: 0px;
  left: 0px;
  height: 60px;
  width: 100%;
  background: rgba(224, 39, 41, 0.95);
  color: white;
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 5px; }
  #webpush-request-enable-bar div {
    flex-grow: 1;
    width: 220px;
    padding: 2px; }
  #webpush-request-enable-bar button {
    flex-grow: 2; }

#tax-app-ad img {
  position: absolute;
  width: 80px;
  height: 80px;
  top: -40px;
  background: white;
  border-radius: 44px;
  border: 4px white solid; }

#tax-app-ad div {
  display: flex;
  flex-direction: column; }
  #tax-app-ad div p {
    margin-top: 50px;
    font-size: 30px;
    color: rgba(224, 39, 41, 0.95);
    font-weight: bold; }
