.publiko-button-icon {
  color: rgba(224, 39, 41, 0.6);
  font-size: 30px;
  cursor: pointer; }
  .publiko-button-icon:hover {
    color: rgba(224, 39, 41, 0.8); }
  .publiko-button-icon:active {
    color: rgba(224, 39, 41, 0.95); }

.publiko-button-icon-circle {
  cursor: pointer;
  color: rgba(224, 39, 41, 0.95);
  height: 40px !important;
  min-width: 40px !important;
  max-width: 40px;
  padding: 8px;
  border-radius: 20px;
  box-sizing: border-box;
  background: rgba(231, 231, 231, 0.95);
  color: white; }
  .publiko-button-icon-circle:hover {
    background: rgba(224, 39, 41, 0.95);
    color: white; }
  .publiko-button-icon-circle:active {
    background: rgba(224, 39, 41, 0.8); }

.publiko-position-button {
  text-align: center;
  color: rgba(224, 39, 41, 0.95);
  width: 100%;
  height: 40px;
  line-height: 36px;
  border: rgba(224, 39, 41, 0.95) 2px solid;
  border-radius: 20px;
  font-weight: bold;
  box-sizing: border-box;
  cursor: pointer; }
  .publiko-position-button:hover {
    background: rgba(224, 39, 41, 0.8);
    color: white; }
  .publiko-position-button.active {
    background: rgba(224, 39, 41, 0.95);
    color: white; }
    .publiko-position-button.active:hover {
      background: rgba(224, 39, 41, 0.95);
      color: white; }
  .publiko-position-button:active {
    background: rgba(224, 39, 41, 0.95); }

.publiko-category-tags {
  padding: 5px 15px; }
  .publiko-category-tags li {
    font-size: 12px;
    padding: 5px 10px;
    background: rgba(231, 231, 231, 0.95);
    color: rgba(224, 39, 41, 0.95);
    margin: 2px 5px;
    border-radius: 5px; }

.publiko-message-button {
  background: rgba(224, 39, 41, 0.95);
  color: white;
  text-align: center;
  padding: 10px 15px;
  cursor: pointer; }
  .publiko-message-button:hover {
    background: rgba(231, 231, 231, 0.95);
    color: rgba(224, 39, 41, 0.95); }

.publiko-message-card-button {
  display: flex;
  text-align: center;
  align-content: center;
  justify-content: center;
  padding: 0px 15px;
  padding-top: 50px;
  min-height: 300px;
  cursor: pointer; }

.publiko-warn {
  color: rgba(224, 39, 41, 0.95); }

#cost-modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 20px 15px 60px 15px;
  box-sizing: border-box;
  min-height: 400px; }
  #cost-modal-content img {
    width: 60px;
    height: 60px; }
  #cost-modal-content nav {
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 60px;
    width: 100%;
    display: flex;
    padding: 0px;
    margin: 0px;
    border-top: black 1px solid; }
    #cost-modal-content nav button {
      width: 50%;
      text-align: center;
      line-height: 60px;
      color: black;
      background: none;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      font-size: 18px;
      font-weight: bold; }
      #cost-modal-content nav button.confirm {
        background: black;
        color: white; }
      #cost-modal-content nav button:disabled, #cost-modal-content nav button[disabled] {
        background: rgba(231, 231, 231, 0.95); }
