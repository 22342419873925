#nav-bar {
  display: flex;
  width: 100%;
  height: 60px;
  line-height: 60px;
  margin: 0px;
  padding: 0px;
  background-color: white;
  color: rgba(224, 39, 41, 0.98);
  border-bottom: 1px solid rgba(128, 128, 128, 0.3);
  font-size: 26px;
  cursor: pointer;
  justify-content: space-between; }
  #nav-bar ul {
    display: flex;
    flex-direction: row;
    padding-right: 10px;
    padding-top: 3px; }
    #nav-bar ul li {
      position: relative;
      margin: 10px 5px;
      font-size: 20px;
      line-height: 30px;
      height: 30px;
      display: flex;
      padding: 5px; }
      #nav-bar ul li i {
        width: 20px;
        height: 20px;
        background: rgba(224, 39, 41, 0.95);
        border-radius: 10px;
        padding-top: 3px;
        box-sizing: border-box; }
      #nav-bar ul li div {
        position: absolute;
        text-align: center;
        width: auto;
        height: 16px;
        font-size: 12px;
        color: white;
        line-height: 16px;
        width: 16px;
        border-radius: calc(16px/2);
        right: 0px;
        top: 0px;
        background: rgba(145, 12, 25, 0.95); }
  #nav-bar figure {
    line-height: 60px;
    padding: 0px;
    padding-left: 10px;
    margin: 0px;
    display: flex;
    align-items: center; }
    #nav-bar figure img {
      margin-right: 10px; }
    #nav-bar figure span {
      font-weight: bold; }
